import clubPicture from "images/shared/know-each-other-section/first.webp";
import blocPicture from "images/shared/know-each-other-section/second.webp";
import Link from "next/link";

import { Card } from "ui/Card";
import { StaticImage } from "ui/StaticImage";
import { useI18n } from "utils/with-i18n.utils";

export const KnowEachOtherSection = () => {
  const i18n = useI18n();

  return (
    <div className="flex flex-col items-center py-14 text-center bg-cornflower-blue lg:py-24">
      <div className="max-w-4xl text-4xl font-medium text-white lg:text-6xl">
        {i18n.t("homepage.know.title")}
      </div>
      <div className="flex flex-col gap-9 px-9 mt-9 max-w-6xl lg:flex-row lg:mt-14">
        <Card
          border="filled"
          className="lg:h-full"
          rootClassname="lg:basis-1/2"
        >
          <div className="relative w-full h-64">
            <StaticImage
              alt="club section decorative image"
              src={clubPicture}
              layout="fill"
              sizes="(max-width: 1024px) 80vw, 33vw"
              className="rounded-t-lg"
              objectFit="cover"
            />
          </div>

          <div className="py-9 px-6 lg:py-11 lg:px-24">
            <p className="text-2xl font-bold">
              {i18n.t("homepage.know.article.title1")}
            </p>
            <p className="text-lg text-davys-grey lg:my-5">
              {i18n.t("homepage.know.article.subtitle1")}
            </p>
            <Link href="/club">
              <a>
                <p className="inline py-1 text-lg font-medium bg-yellow">
                  {i18n.t("homepage.know.article.cta")}
                </p>
              </a>
            </Link>
          </div>
        </Card>

        <Card
          border="filled"
          className="lg:h-full"
          rootClassname="lg:basis-1/2"
        >
          <div className="relative w-full h-64">
            <StaticImage
              alt="blog section decorative image"
              src={blocPicture}
              layout="fill"
              sizes="(max-width: 1024px) 80vw, 33vw"
              className="rounded-t-lg"
              objectFit="cover"
            />
          </div>

          <div className="py-9 px-6 lg:py-11 lg:px-24">
            <p className="text-2xl font-bold">
              {i18n.t("homepage.know.article.title2")}
            </p>
            <p className="text-lg text-davys-grey lg:my-5">
              {i18n.t("homepage.know.article.subtitle2")}
            </p>
            <a href="/blog">
              <p className="inline py-1 text-lg font-medium bg-yellow">
                {i18n.t("homepage.know.article.cta")}
              </p>
            </a>
          </div>
        </Card>
      </div>
    </div>
  );
};
