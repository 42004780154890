import {
  HeroPictureDocument,
  HeroPictureQuery,
} from "app/home/Hero/hero-picture.query.generated";
import { stripMetadata } from "ui/StrapiImage";
import { initializeApollo } from "utils/with-apollo.utils";

import { HeroProps } from ".";

export const fetchHero = async (): Promise<HeroProps> => {
  const apolloClient = initializeApollo();

  const { data } = await apolloClient.query<HeroPictureQuery>({
    query: HeroPictureDocument,
  });

  const dataAttributes = data.websiteSetting?.data?.attributes;
  const isBlackFriday = dataAttributes?.isBlackFriday;

  const heroData = dataAttributes?.heroPicture?.data;
  const heroDataMobile = !!dataAttributes?.heroPictureMobile?.data
    ? dataAttributes?.heroPictureMobile?.data
    : heroData;

  if (isBlackFriday) {
    const bfHeroData = !!dataAttributes?.heroPictureBlackFriday?.data
      ? dataAttributes?.heroPictureBlackFriday?.data
      : heroData;
    const bfHeroDataMobile = !!dataAttributes?.heroPictureBlackFridayMobile
      ?.data
      ? dataAttributes?.heroPictureBlackFridayMobile?.data
      : bfHeroData;

    return {
      desktop: stripMetadata(bfHeroData?.attributes),
      mobile: stripMetadata(bfHeroDataMobile?.attributes),
    };
  }

  return {
    desktop: stripMetadata(heroData?.attributes),
    mobile: stripMetadata(heroDataMobile?.attributes),
  };
};
