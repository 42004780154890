import Marquee from "react-fast-marquee";

import { TrustPilotReview } from "app/home/Reviews/TrustPilotReview";
import { TrustpilotWidget } from "shared/TrustpilotWidget";
import { CheckeredContainer } from "ui/CheckeredContainer";
import { useI18n } from "utils/with-i18n.utils";

export type Review = {
  id: string;
  comment: string;
  date: string;
  rating: number;
  author: string;
};

type ReviewsProps = {
  reviews: Review[];
};

export function Reviews({ reviews }: ReviewsProps) {
  const i18n = useI18n();

  const half = Math.ceil(reviews.length / 2);
  const firstHalf = reviews.slice(0, half);
  const secondHalf = reviews.slice(half);

  return (
    <CheckeredContainer className="flex flex-col items-center">
      <h2 className="px-8 mt-14 mb-6 max-w-4xl text-4xl font-medium text-center lg:mt-24 lg:mb-6 lg:text-6xl">
        {i18n.t("homepage.rentvsbuy.subtitle.join")}
      </h2>

      <TrustpilotWidget className="lg:w-[422px]" />

      <Marquee
        speed={75}
        gradient={false}
        className="[&>*]:justify-around mt-9 mb-2 lg:mt-28"
      >
        {firstHalf.map(({ rating, comment, author, date }) => (
          <TrustPilotReview
            key={`${author}-${rating}`}
            rating={rating}
            comment={comment}
            author={author}
            date={date}
          />
        ))}
      </Marquee>
      <Marquee
        speed={75}
        gradient={false}
        direction="right"
        className="[&>*]:justify-around mb-12 lg:mb-36"
      >
        {secondHalf.map(({ rating, comment, author, date }) => (
          <TrustPilotReview
            key={`${author}-${rating}`}
            rating={rating}
            comment={comment}
            author={author}
            date={date}
          />
        ))}
      </Marquee>
    </CheckeredContainer>
  );
}
