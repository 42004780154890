import Link from "next/link";

import MobileClubLogoGroup from "assets/mobile-club-logo-group.svg";
import WhiteCheckSVG from "assets/white-check-outlined.svg";
import WhiteCrossSVG from "assets/white-cross.svg";
import { Button } from "ui/Button";
import { Card } from "ui/Card";
import { CheckeredContainer } from "ui/CheckeredContainer";
import { useI18n } from "utils/with-i18n.utils";

import ElipsisSVG from "./elipsis.svg";

export const RentVsBuy = () => {
  const i18n = useI18n();

  const homepageArgs = [
    "homepage.args.1",
    "homepage.args.2",
    "homepage.args.3",
    "homepage.args.4",
    "homepage.args.5",
  ];

  return (
    <CheckeredContainer className="flex flex-col items-center">
      <div className="px-6 my-12 text-4xl font-semibold text-center lg:my-24 lg:text-6xl">
        <h2>
          {i18n.t("homepage.rentvsbuy.title.rent")}
          <span className="italic font-normal">
            {i18n.t("homepage.rentvsbuy.title.vs")}
          </span>
          {i18n.t("homepage.rentvsbuy.title.buy")}
        </h2>

        <p className="mt-6 text-base font-normal">
          {i18n.t("homepage.rentvsbuy.subtitle")}
        </p>
      </div>
      <div className="relative w-full max-w-7xl">
        <Card className="flex flex-col gap-y-3 justify-between py-4 px-[5%] bg-white rounded-none border-black lg:gap-y-6 lg:p-12 lg:rounded-xl">
          <div className="flex justify-between items-center">
            <p className="flex basis-1/2 text-xl font-bold lg:text-4xl ">
              <span className="w-full">{i18n.t("homepage.args.title")}</span>
            </p>
            <div className="flex basis-1/4 justify-center items-center mt-[-3%] lg:hidden">
              <div className="flex justify-center items-center w-[100px]">
                <MobileClubLogoGroup viewBox="-20 -20 143 100" />
              </div>
            </div>
            <div className="hidden basis-1/4 justify-center items-center mt-[-6%] lg:flex">
              <MobileClubLogoGroup viewBox="0 0 113 60" />
            </div>
            <p className="basis-1/4 font-bold text-center lg:text-2xl">
              {i18n.t("homepage.buy")}
            </p>
          </div>
          {homepageArgs.map((arg) => {
            return (
              <div key={arg} className="flex justify-between items-center">
                <p className="flex basis-1/2">
                  <ElipsisSVG className="mt-2 w-6" />
                  <span className="w-full">{i18n.t(arg)}</span>
                </p>
                <div className="flex basis-1/4 justify-center items-center">
                  <WhiteCheckSVG />
                </div>
                <div className="flex basis-1/4 justify-center items-center">
                  <WhiteCrossSVG />
                </div>
              </div>
            );
          })}
          {/*
            const Card_px = 5%
            ---
            Depends on flex-basis in Card
            current: |---basis-1/2---|---basis-1/4---|---basis-1/4---|
            ---
            Some equation to find relation between values:
              top% = - (height% - 100) / 2
              right% = 37.5 - ((6 * Card_px) / 8) - (width% / 2)
          */}
          <div
            className="flex absolute top-[-5%] right-[28.75%] -z-10 flex-col-reverse items-center
            pb-[2%] w-[20%] h-[110%] bg-yellow rounded-xl border border-black drop-shadow-none
            lg:top-[-12.5%] lg:right-[26.25%] lg:w-[25%] lg:h-[125%] lg:rounded-lg lg:drop-shadow-black lg:transform-gpu"
          >
            <Link href="/smartphone" className="hidden lg:inline-block">
              <a aria-label="choisir un telephone">
                <Button className="hidden text-lg font-medium whitespace-nowrap bg-white lg:inline-block">
                  {i18n.t("homepage.cta")}
                </Button>
              </a>
            </Link>
          </div>
        </Card>
      </div>

      <Link href="/smartphone">
        <a aria-label="choisir un telephone">
          <Button className="mt-16 text-lg font-medium lg:hidden">
            {i18n.t("homepage.cta")}
          </Button>
        </a>
      </Link>
    </CheckeredContainer>
  );
};
